import React from "react";
import { Menu } from "antd";
import { FormattedMessage } from "react-intl";

import "../style.css";

const items = ({ isFullAdmin, canViewAdminOverview, canViewAndEditInstitutions, canSearchUsers }) => {
  return [
    {
      key: "home",
      label: (
        <a href="/dashboard">
          <FormattedMessage id="dashboard.layout.home" defaultMessage="Home" description="Layout: Link to home" />
        </a>
      )
    },
    ...(isFullAdmin || canViewAdminOverview || canViewAndEditInstitutions || canSearchUsers
      ? [
          {
            key: "admin-tasks",
            label: (
              <FormattedMessage
                id="dashboard.index.adminTasks"
                defaultMessage="Admin tasks"
                description="Sub menu group title to group a bunch of links regarding administrator tasks"
              />
            ),
            children: [
              ...(isFullAdmin
                ? [
                    {
                      key: "dashboard.index.api",
                      label: (
                        <a href="/dashboard/config">
                          <FormattedMessage id="dashboard.index.api" defaultMessage="API config" description="link to the api config" />
                        </a>
                      )
                    }
                  ]
                : []),
              ...(canViewAdminOverview
                ? [
                    {
                      key: "dashboard.index.admins",
                      label: (
                        <a href="/admin/administrators">
                          <FormattedMessage
                            id="dashboard.index.admins"
                            defaultMessage="See Q1.6 Administrators"
                            description="link to the admin overview page"
                          />
                        </a>
                      )
                    }
                  ]
                : []),
              ...(canViewAndEditInstitutions
                ? [
                    {
                      key: "dashboard.index.institutions",
                      label: (
                        <a href="/admin/institutions">
                          <FormattedMessage
                            id="dashboard.index.institutions"
                            defaultMessage="See Q1.6 Centres/Hospitals"
                            description="link to the centres/hospitals overview page"
                          />
                        </a>
                      )
                    }
                  ]
                : []),
              ...(isFullAdmin
                ? [
                    {
                      key: "dashboard.index.projects",
                      label: (
                        <a href="/admin/projects">
                          <FormattedMessage
                            id="dashboard.index.projects"
                            defaultMessage="Projects"
                            description="link to the projects page"
                          />
                        </a>
                      )
                    },
                    {
                      key: "dashboard.index.exportRequests",
                      label: (
                        <a href="/admin/export-requests">
                          <FormattedMessage
                            id="dashboard.index.exportRequests"
                            defaultMessage="Export Requests"
                            description="Link to the export requests"
                          />
                        </a>
                      )
                    },
                    {
                      key: "dashboard.index.reports",
                      label: (
                        <a href="/dashboard/reports">
                          <FormattedMessage id="dashboard.index.reports" defaultMessage="Reports" description="Link to the Reports" />
                        </a>
                      )
                    },
                    {
                      key: "dashboard.index.monitoringPage",
                      label: (
                        <a href="/admin/monitor">
                          <FormattedMessage
                            id="dashboard.index.monitoringPage"
                            defaultMessage="Monitoring page"
                            description="Link to the Monitoring page"
                          />
                        </a>
                      )
                    },
                    {
                      key: "dashboard.index.featureFlags",
                      label: (
                        <a href="/admin/featureflags">
                          <FormattedMessage
                            id="dashboard.index.featureFlags"
                            defaultMessage="Feature Flags"
                            description="Link to Feature Flags"
                          />
                        </a>
                      )
                    },
                    {
                      key: "dashboard.index.excelTool",
                      label: (
                        <a href="/admin/questionnaires/import">
                          <FormattedMessage
                            id="dashboard.index.excelTool"
                            defaultMessage="Import questionnaire excel"
                            description="Link to Excel Tool"
                          />
                        </a>
                      )
                    },
                    {
                      key: "dashboard.index.anonymisationWarnings",
                      label: (
                        <a href="/admin/questionnaires/anonymisationWarnings">
                          <FormattedMessage
                            id="dashboard.index.anonymisationWarnings"
                            defaultMessage="Anonymisation warnings"
                            description="Anonymisation warnings"
                          />
                        </a>
                      )
                    },
                    {
                      key: "dashboard.index.uploadPublicFiles",
                      label: (
                        <a href="/admin/upload-public-files">
                          <FormattedMessage
                            id="dashboard.index.uploadPublicFiles"
                            defaultMessage="Upload Public Files"
                            description="Link to public S3 files upload page"
                          />
                        </a>
                      )
                    }
                  ]
                : []),
              ...(canSearchUsers
                ? [
                    {
                      key: "dashboard.index.search",
                      label: (
                        <a href="/admin/search">
                          <FormattedMessage id="dashboard.index.search" defaultMessage="Search" description="Search" />
                        </a>
                      )
                    }
                  ]
                : [])
            ]
          }
        ]
      : []),
    isFullAdmin
      ? {
          key: "queue-management-hub",
          label: <a href="/admin/queue-management-hub">Queue Management Hub</a>
        }
      : {},
    ...(isFullAdmin
      ? [
          {
            key: "bull-queue-tools",
            label: "Bull Queue tools",

            children: [
              { key: "dashboard.index.queueExportBullJobs", label: <a href="/admin/bull/export">Queue export jobs</a> },
              { key: "dashboard.index.queueImportBullJobs", label: <a href="/admin/bull/import">Queue import jobs</a> },
              { key: "dashboard.index.queueAddBullJob", label: <a href="/admin/bull/jobs/new">Queue add job</a> }
            ]
          }
        ]
      : [])
  ];
};

const AdminLinks = ({ adminLinksViewModel }) => {
  return (
    <Menu theme="light" mode="horizontal" style={{ borderBottom: "none", backgroundColor: "#f8f8f8" }} items={items(adminLinksViewModel)} />
  );
};
export default AdminLinks;
